*{
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.rbc-event {
  padding: 0 !important;
  /* min-height: 24px !important;
  background-color: unset !important;
  border: none !important; */
}

/* .rbc-event-label {
  display: none !important;
} */
